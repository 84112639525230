var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vxe-modal",
        {
          staticClass: "vxe-container",
          attrs: {
            width: _vm.modalWidth,
            height: _vm.currentModule.params.height,
            "show-zoom": true,
            resize: "",
            "lock-view": false,
            mask: false,
            position: _vm.currentModule.params.position,
            transfer: "",
          },
          on: { close: _vm.handleCloseModal },
          model: {
            value: _vm.ModalVisible,
            callback: function ($$v) {
              _vm.ModalVisible = $$v
            },
            expression: "ModalVisible",
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _vm.navigateBackVisible
                ? _c("a-icon", {
                    staticClass: "title-arrow",
                    attrs: { type: "arrow-left" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleNavigateBack.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.currentModule.params.parentTitle
                      ? _vm.currentModule.params.parentTitle
                      : ""
                  ) + _vm._s(_vm.currentModule.title)
                ),
              ]),
            ],
            1
          ),
          _c(
            "keep-alive",
            [
              _c(_vm.currentModule.component, {
                tag: "component",
                attrs: { params: _vm.currentModule.params },
                on: {
                  close: _vm.handleClose,
                  navigateTo: _vm.handleNavigateTo,
                  update: _vm.handleUpdate,
                  moduleEvent: _vm.handleModuleEvent,
                  toTrackVideo: _vm.handleToTrackVideo,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }